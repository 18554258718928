"use client";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { RoleType, UserPlan, UserStatus } from "../dto/interfaces";
import { QueryClient, QueryClientProvider } from "react-query";
import Cookies from "js-cookie";
import axios from "axios";
import { navigate } from "gatsby";
import { URL_API } from "../utils/constants";
import { getTrackerInstance } from "../utils/trackerSingleton";
import queryString from "query-string";

export enum Language {
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  GERMAN = "GERMAN",
  PORTUGUESE = "PORTUGUESE",
  FRENCH = "FRENCH",
  ITALIAN = "ITALIAN",
}

export enum PaymentMethod {
  CHECK = "CHECK",
  MASTER_CARD = "MASTER_CARD",
  VISA = "VISA",
  AMEX = "AMEX",
  BANK_TRANSFER = "BANK_TRANSFER",
  SINPE_MOVIL = "SINPE_MOVIL",
  PAYPAL = "PAYPAL",
  CRYPTO = "CRYPTO",
  CASH = "CASH",
  OTHER = "OTHER",
  TASA_CERO = "TASA_CERO",
  CUOTAS = "CUOTAS",
  CUOTA_LITIS = "CUOTA_LITIS",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

interface Specialization {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: string;
  updatedAt: string;
  practiceArea?: PracticeArea;
}

interface PracticeArea {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: string;
  updatedAt: string;
}

interface UserProfessional {
  id: number;
  specializations: Specialization[];
  practiceAreas: PracticeArea[];
  languages: Language[];
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserPaymentMethod {
  id: number;
  methods: PaymentMethod[];
  hourlyRate: string;
  consultRate: string;
  userProfile: UserProfile;
  currency: string;
  createdAt: string;
  updatedAt: string;
}

interface UserEducation {
  id: number;
  university: string;
  degree: string;
  year: number;
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserCertification {
  id: number;
  title: string;
  organization: string;
  year: number;
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}
interface UserReconocimientos {
  id: number;
  title: string;
  organization: string;
  year: number;
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserArticle {
  id: number;
  title: string;
  organization: string;
  year: number;
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserAssociation {
  id: number;
  title: string;
  organization: string;
  year: number;
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserAddress {
  id: number;
  province: string;
  canton: string;
  district: string;
  exactAddress: string;
  userProfile: UserProfile;
  billingProvince: string;
  billingCanton: string;
  billingDistrict: string;
  billingExactAddress: string;
  billingDNI: string;
  billingName: string;
  sameAsShipping: boolean;
  plusCode: string;
  createdAt: string;
  updatedAt: string;
}

interface UserAbout {
  id: number;
  avatar: string;
  gender: Gender;
  dateOfBirth: string;
  aboutMe: string;
  gallery: string[];
  userProfile: UserProfile;
  createdAt: string;
  updatedAt: string;
}

interface UserProfile {
  id: number;
  firmName: string;
  dni: string;
  facebook: string;
  instagram: string;
  licenseNumber: string;
  website: string;
  certifications: UserCertification[];
  reconocimientos: UserReconocimientos[];
  educations: UserEducation[];
  articles: UserArticle[];
  associations: UserAssociation[];
  address: UserAddress;
  about: UserAbout;
  paymentMethod: UserPaymentMethod;
  professional: UserProfessional;
  createdAt: string;
  updatedAt: string;
  typeOfConsultation: string[];
  calendlyUrl: string;
  linkedin: string;
  whatsapp: string;
  tiktok: string;
  customConfig: {
    canReceiveReviews: boolean;
    canReceiveRecommendations: boolean;
  };
}

interface UserLogin {
  id: string;
  email: string;
  fullName: string;
  role: RoleType;
  status: UserStatus;
  phoneNumber?: string;
  userProfile: UserProfile;
  favoriteAttorneys?: string[];
  plan: UserPlan;
}

interface AppContextProps {
  accessToken?: string;
  user?: UserLogin;
  specializations?: Specialization[];
  practiceAreas?: PracticeArea[];
  firmNames?: string[];
  role: RoleType;
  signOut: (fromHome?: boolean) => void;
  setRole: (newRole: RoleType) => void;
  setContextValue: (key: keyof AppContextProps, value: any) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState<RoleType>(RoleType.ATTORNEY);
  const [user, setUser] = useState<UserLogin | undefined>(undefined);
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [practiceAreas, setPracticeAreas] = useState<PracticeArea[]>([]);
  const [firmNames, setFirmNames] = useState<string[]>([]);
  const [accessToken, setAccessToken] = useState<string | undefined>(
    Cookies.get("accessToken")
  );

  // Utilizar useEffect para asegurarse de que window esté disponible
  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = sessionStorage.getItem("userToken");
      if (token) {
        setAccessToken(token);
      }
    }
  }, []);

  const signOut = (fromHome = false) => {
    setRole(RoleType.BASIC);
    setUser(undefined);
    setAccessToken(undefined);
    sessionStorage.removeItem("userToken");
    Cookies.remove("accessToken");
    if (!fromHome) {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const tracker = getTrackerInstance?.();
      if (tracker) {
        tracker.start();
        if (user?.email) tracker.setUserID(user?.email);
      }
    }
  }, [getTrackerInstance, user?.email]);

  useEffect(() => {
    if (accessToken && !user) {
      axios
        .get(`${URL_API}/auth/check`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }) => {
          setContextValue("user", { ...data });

          setContextValue("role", data.role);
          if (data.role === RoleType.ATTORNEY) {
            axios
              .get(`${URL_API}/users/profile/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then(({ data }: any) => {
                const { user, ...userProfile } = data;

                setContextValue("user", {
                  ...user,
                  userProfile,
                });
              });
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [accessToken, user]);

  useEffect(() => {
    const accessTokenEview = sessionStorage.getItem("accessToken");
    if (accessToken && !accessTokenEview) {
      Cookies.set("accessToken", accessToken);
    }
  }, [accessToken]);

  const handleSetRole = (newRole: RoleType) => {
    setRole(newRole);
  };

  const stateSetters = {
    role: setRole,
    user: setUser,
    accessToken: setAccessToken,
    specializations: setSpecializations,
    practiceAreas: setPracticeAreas,
    firmNames: setFirmNames,
  };

  const setContextValue = (key: keyof AppContextProps, value: any) => {
    const setter =
      stateSetters[
        key as
          | "role"
          | "user"
          | "accessToken"
          | "specializations"
          | "practiceAreas"
          | "firmNames"
      ];

    if (setter) {
      setter(value);
    }
  };

  const appContextValue: AppContextProps = {
    role,
    setRole: handleSetRole,
    user,
    accessToken,
    setContextValue,
    signOut,
    specializations,
    practiceAreas,
    firmNames,
  };

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={appContextValue}>
        {!isLoading && children}
      </AppContext.Provider>
    </QueryClientProvider>
  );
};
