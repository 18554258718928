import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Badge, Button as ButtonFlowbite, Tooltip } from "flowbite-react";
import axios from "axios";
import { useMutation } from "react-query";
import { URL_API } from "../../../../utils/constants";
import { useAppContext } from "../../../../context/AppProvider";
import {
  ProfessionalDto,
  PROFILE_STEPS,
  UserStatus,
} from "../../../../dto/interfaces";
import { Button, Select } from "../../../../components/base";
import toast from "react-hot-toast";
import { navigate } from "gatsby";
import { fetchUser } from "../../../../components/views/AdditionalInformation/EducationForm";

export const LANGUAGES = [
  { id: "ENGLISH", key: "english", en: "English", es: "Inglés" },
  { id: "SPANISH", key: "spanish", en: "Spanish", es: "Español" },
  { id: "GERMAN", key: "german", en: "German", es: "Alemán" },
  { id: "PORTUGUESE", key: "portuguese", en: "Portuguese", es: "Portugués" },
  { id: "FRENCH", key: "french", en: "French", es: "Francés" },
  { id: "ITALIAN", key: "italian", en: "Italian", es: "Italiano" },
];

const ProfessionalForm = () => {
  const { t } = useTranslation();
  const {
    user,
    practiceAreas: practicesAreasRaw,
    specializations,
    accessToken,
    setContextValue,
  } = useAppContext();
  const practiceAreas = practicesAreasRaw.sort((a, b) =>
    a?.es?.localeCompare?.(b?.es)
  );
  const isPro = user?.plan === "PRO";
  const isEdit = !!user?.userProfile?.professional?.specializations;

  const [practiceAreasState, setPracticeAreasState] = useState<
    { practiceAreaId?: number; specializations?: number[] }[]
  >([]);
  console.log(
    "🚀 ~ ProfessionalForm ~ practiceAreasState:",
    practiceAreasState
  );

  const [languagesState, setLanguagesState] = useState<string[]>([]);
  const [professionalError, setProfessionalError] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (user?.userProfile?.professional?.practiceAreas?.length > 0) {
      const newPracticeAreas =
        user?.userProfile?.professional?.practiceAreas.map((pa) => ({
          practiceAreaId: pa.id,
          specializations: user?.userProfile?.professional?.specializations
            ?.filter(
              (s) =>
                s.id !==
                specializations.find((sp) => sp.practiceArea.id === pa.id)?.id
            )
            .map((s) => s.id),
        }));
      setPracticeAreasState(newPracticeAreas);
    } else if (practiceAreasState.length === 0) {
      addPracticeArea(); // Solo agrega un área de práctica por defecto si el estado está vacío
    }

    if (user?.userProfile?.professional?.languages) {
      setLanguagesState(user?.userProfile?.professional?.languages);
    }
  }, [user, specializations, practiceAreasState.length]);

  const mutation = useMutation(
    (data: ProfessionalDto) =>
      axios.post(
        `${URL_API}/user-profile/${user?.userProfile?.id}/professional`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    {
      onError: (e: any) => {
        toast.error("Ha ocurrido un error al guardar la información");
        if (e.response?.status === 401) {
          navigate("/auth/login");
        }
        setProfessionalError("Error al guardar la información.");
      },
      onSuccess: () => {
        toast.success("La información se ha guardado correctamente");
        fetchUser(user, accessToken, setContextValue);
        isEdit
          ? navigate("/auth/user-profile/")
          : navigate(PROFILE_STEPS[3].path);
      },
    }
  );

  const handlePracticeAreaChange = (
    index: number,
    values: number | number[]
  ) => {
    const updatedPracticeAreas = [...practiceAreasState];
    updatedPracticeAreas[index].practiceAreaId = values as number;
    updatedPracticeAreas[index].specializations = []; // Reset specializations when practice area changes
    setPracticeAreasState(updatedPracticeAreas);
  };

  const handleSpecializationChange = (
    index: number,
    values: number[] | number
  ) => {
    const updatedPracticeAreas = [...practiceAreasState];
    updatedPracticeAreas[index].specializations = Array.isArray(values)
      ? values
      : [values];
    setPracticeAreasState(updatedPracticeAreas);
  };

  const handleLanguageChange = (values: string[] | string) => {
    if (Array.isArray(values)) {
      setLanguagesState(values);
    } else {
      setLanguagesState([values]);
    }
  };

  const addPracticeArea = (e?: any) => {
    setPracticeAreasState((prevState) => [
      ...prevState,
      { practiceAreaId: undefined, specializations: [] },
    ]);
    e?.preventDefault();
  };

  const removePracticeArea = (index: number) => {
    const updatedPracticeAreas = [...practiceAreasState];
    console.log(
      "🚀 ~ removePracticeArea ~ updatedPracticeAreas:",
      updatedPracticeAreas
    );
    updatedPracticeAreas.splice(index, 1);
    setPracticeAreasState(updatedPracticeAreas);

    // Asegura que siempre haya al menos un área de práctica
    if (updatedPracticeAreas.length === 0) {
      addPracticeArea();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const transformedData: ProfessionalDto = {
      practiceAreas: practiceAreasState
        .map((pa) => pa.practiceAreaId!)
        .filter(Boolean),
      specializations: practiceAreasState.flatMap(
        (pa) => pa.specializations || []
      ),
      languages: languagesState,
    };
    mutation.mutate(transformedData);
  };

  return (
    <form onSubmit={handleSubmit} className="mx-auto w-full">
      <p className="text-gray-500 text-center py-4">
        {!isEdit ? `${t("Step")} 3/6:` : `${t("Update")}`}{" "}
        {t(PROFILE_STEPS[2].name)}
      </p>

      {practiceAreasState.map((field, index) => (
        <div key={index} className="mb-6 border p-4 rounded-lg relative">
          {index > 0 && (
            <Badge
              color="teal"
              className="absolute top-0 left-0 transform translate-x-2 -translate-y-2"
            >
              Pro
            </Badge>
          )}
          <Select
            items={practiceAreas}
            value={field.practiceAreaId}
            onChange={(value) => handlePracticeAreaChange(index, value)}
            placeholder={t("Select a Practice Area")}
          />
          <Select
            items={specializations.filter(
              (s) =>
                s.practiceArea && s.practiceArea.id === field.practiceAreaId
            )}
            value={field.specializations}
            onChange={(values) => handleSpecializationChange(index, values)}
            multiple={true}
            placeholder={t("Select a Specialization")}
          />
          {
            <ButtonFlowbite
              onClick={() => removePracticeArea(index)}
              color="red"
              size="sm"
              className="mt-2"
            >
              {t("Remove Practice Area")}
            </ButtonFlowbite>
          }
        </div>
      ))}

      {isPro && practiceAreasState?.length < 3 && (
        <div className="mb-6">
          <Button
            type="button"
            onClick={addPracticeArea}
            variant="outline"
            className="w-full"
          >
            {t("Add Practice Area")}
          </Button>
        </div>
      )}
      {!isPro && (
        <Tooltip
          content={t(
            "To add more practice areas, you need a pro subscription."
          )}
        >
          <div className="mb-6 w-full relative ">
            <Badge
              color="teal"
              className="absolute top-0 left-0 transform translate-x-2 -translate-y-2 z-10"
            >
              Pro
            </Badge>
            <ButtonFlowbite
              className="w-full"
              onClick={() => {}}
              size="sm"
              color="red"
              disabled
            >
              {t("Add Practice Area")}
            </ButtonFlowbite>
          </div>
        </Tooltip>
      )}

      <div className="mb-6">
        <Select
          placeholder={t("Select a Language")}
          items={LANGUAGES}
          value={languagesState}
          onChange={handleLanguageChange}
          multiple={true}
        />
      </div>

      {professionalError && (
        <div className="my-4 flex w-full justify-center">
          <span className="w-full text-center font-medium text-red-500">
            {professionalError}
          </span>
        </div>
      )}

      <ButtonFlowbite
        type="submit"
        className="h-12 w-full"
        disabled={mutation.isLoading}
      >
        {mutation.isLoading ? (
          <Trans>Saving...</Trans>
        ) : isEdit ? (
          t("Save")
        ) : (
          t("Continue")
        )}
      </ButtonFlowbite>
    </form>
  );
};

export default ProfessionalForm;
