import { FaBalanceScale, FaCameraRetro, FaGraduationCap } from "react-icons/fa";
import { FaColonSign } from "react-icons/fa6";
import { HiAcademicCap } from "react-icons/hi";
import { ImLocation, ImProfile } from "react-icons/im";
import { SiAboutdotme } from "react-icons/si";

export interface LoginDto {
  email: string;
  password: string;
}

export enum RoleType {
  BASIC = "BASIC",
  ATTORNEY = "ATTORNEY",
  ADMIN = "ADMIN",
}
export interface RegisterDto {
  email: string;
  password: string;
  rePassword: string;
  fullName: string;
  phoneNumber: string;
  role: RoleType;
  plan: UserPlan;
}

export interface ForgotPasswordDto {
  email: string;
}

export enum TypeOfConsultation {
  ONLINE = "ONLINE",
  IN_PERSON = "IN_PERSON",
  AT_HOME = "AT_HOME",
}
export interface GeneralProfile {
  firmName: string;
  licenseNumber: string;
  website: string;
  phoneNumber: string;
  typeOfConsultation?: TypeOfConsultation | null; // Puede ser opcional y nulo
  facebook: string;
  instagram: string;
  tiktok: string;
  dni: string;
  customConfig?: {
    canReceiveReviews?: boolean;
    canReceiveRecommendations?: boolean;
  };
}
export interface AddressProfile {
  district: string;
  exactAddress: string;
  province: string;
  canton: string;
}

export interface ProfessionalProfile {
  practiceAreas?: number[];
  specializations?: number[];
  languages?: string[];
  isNotary?: boolean;
}
export interface Specialization {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PracticeArea {
  id: number;
  key: string;
  en: string;
  es: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum Language {
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  GERMAN = "GERMAN",
  PORTUGUESE = "PORTUGUESE",
  FRENCH = "FRENCH",
  ITALIAN = "ITALIAN",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface AboutProfile {
  avatar: string;
  gender: string;
  dateOfBirth: Date;
  aboutMe: string;
  gallery: string[];
}
export interface PaymentMethodsProfile {
  hourlyRate: string;
  methods: string[];
  consultRate: string;
  currency: string;
}

export interface ProfessionalDto extends ProfessionalProfile {}
export interface UserProfile extends GeneralProfile {}
export interface GeneralDto extends GeneralProfile {}
export interface AddressDto extends AddressProfile {}
export interface AboutDto extends AboutProfile {}
export interface PaymentMethodsDto extends PaymentMethodsProfile {}

export const PROFILE_STEPS = [
  {
    name: "General Profile",
    path: "/auth/user-profile/general/",
    icon: ImProfile,
    label: "PRO",
  },
  {
    name: "Address",
    path: "/auth/user-profile/address/",
    icon: ImLocation,
    label: "PRO",
  },
  {
    name: "Professional Profile",
    path: "/auth/user-profile/professional/",
    icon: FaBalanceScale,
    label: "PRO",
  },
  {
    name: "Payment Method",
    path: "/auth/user-profile/payment-methods/",
    icon: FaColonSign,
  },
  {
    name: "About Me",
    path: "/auth/user-profile/about/",
    icon: FaCameraRetro,
    label: "PRO",
  },
  {
    name: "Additional Information",
    path: "/auth/user-profile/additional-information/",
    icon: FaGraduationCap,
  },
];
export enum UserStatus {
  NEW = "NEW", // Usuario recién creado, aún no ha llenado su perfil
  PROFILE_PENDING_REVIEW = "PROFILE_PENDING_REVIEW", // Perfil completado, esperando revisión por un administrador
  SUSPENDED = "SUSPENDED", // Usuario suspendido
  ACTIVE = "ACTIVE", // Usuario activo con suscripción gratuita
}

export const StatusOfUsers = {
  NEW: "Usuario nuevo, pendiente de completar perfil",
  PROFILE_PENDING_REVIEW: "Pendiente de aprobación ",
  SUSPENDED: "Suspendido",
  ACTIVE: "Activo",
};
export enum UserPlan {
  BASIC = "BASIC",
  PRO = "PRO",
}
